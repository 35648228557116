import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/topics/:id',
    name: 'Topic',
    component: () => import('@/views/Topic'),
  },
  {
    path: '/articles/:id',
    name: 'Article',
    component: () => import('@/views/Article'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search'),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  console.log("scrollBehavior: to=", to, ', from=', from, ', savedPosition=', savedPosition);

  if (from.name === 'Article') {
    return savedPosition;
  }

  return { x: 0, y: 0 };
};

export default new VueRouter({
    mode: process.env.NODE_ENV === 'production' ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
});
