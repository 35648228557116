import axios from 'axios';

const config = {
  baseUrl: process.env.NODE_ENV === 'production' ? 'http://esdmnews.com/api': '/api',
};

export default {
  async getTopics() {
    return await axios.get(`${config.baseUrl}/topics.php`);
  },

  async getArticles(page) {
    if (page) {
      return await axios.get(`${config.baseUrl}/articles.php?page=${page}`);
    } else {
      return await axios.get(`${config.baseUrl}/articles.php`);
    }
  },

  async getArticlesByTopic(id, page) {
    if (page) {
      return await axios.get(`${config.baseUrl}/articlesByTopic.php?id=${id}&page=${page}`);
    } else {
      return await axios.get(`${config.baseUrl}/articlesByTopic.php?id=${id}`);
    }
  },

  async searchArticles(query, page) {
    if (page) {
      return await axios.get(`${config.baseUrl}/search.php?query=${query}&page=${page}`);
    } else {
      return await axios.get(`${config.baseUrl}/search.php?query=${query}`);
    }
  },
  
  async getArticle(id) {
    return await axios.get(`${config.baseUrl}/article.php?id=${id}`);
  },
};
