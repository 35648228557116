<template>
  <v-app>
    <app-bar />
    <navigation-drawer />
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// TODO 검색어 보이게
// TODO HeadlineView
// TODO 공유 (카카오톡, URL, 페이스북, 트위터)
// TODO 메타태그 (og, twitter) + 제목
// TODO Noto Sans KR 폰트 성능 저하
// TODO 글자 크게 작게
// TODO 광고
// TODO Google Analytics
import AppBar from './components/AppBar'

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer: () => import('./components/NavigationDrawer'),
  },
};
</script>

<style scoped>
.main {
  background-color: #f6f6f6;
}
</style>