import api from '../../api';

const state = () => ({
  keyword: null,

  name: null,
  page: 0,
  headlines: null,
  topic: null,
  query: null,
  items: null,
  hasNext: false,
  itemsError: false,

  item: null,
  itemError: false,
});

const getters = {};

const actions = {
  async getArticles({ commit, dispatch, state }) {
    dispatch('app/showSpinner', null, { root: true });

    const name = 'Home';

    console.log('actions.getArticles: name=', (state.name ? state.name : 'NONE'), '->', name);

    if (state.name && state.name != name) {
      commit('initializeItems');
    }

    console.log('actions.getArticles: page=', state.page);

    try {
      const response = await api.getArticles(state.page + 1);

      console.log('actions.getArticles: response=', response);
  
      commit('incrementPage', name);

      if (response.data.headlines) {
        commit('setHeadlines', response.data.headlines);
      }
      commit('addArticles', response.data);
    } catch (error) {
      console.log('actions.getArticles: error=', error);
      
      commit('setItemsError', true);
    } finally {
      dispatch('app/hideSpinner', null, { root: true });
    }
  },

  async getArticlesByTopic({ commit, dispatch, state }, topicId) {
    dispatch('app/showSpinner', null, { root: true });

    const name = 'Topic';

    console.log('actions.getArticlesByTopic: name=', (state.name ? state.name : 'NONE'), '->', name);
    console.log('actions.getArticlesByTopic: topicId=', (state.topic ? state.topic.id : 'NONE'), '->', topicId);

    if ((state.name && state.name != name) || (state.topic && state.topic.id != topicId)) {
      commit('initializeItems');
    }

    console.log('actions.getArticlesByTopic: page=', state.page);

    try {
      const response = await api.getArticlesByTopic(topicId, state.page + 1);

      console.log('actions.getArticlesByTopic: response=', response);
  
      commit('incrementPage', name);
  
      if (response.data.topic) {
        commit('setTopic', response.data.topic);
      }
      commit('addArticles', response.data);
    } catch (error) {
      console.log('actions.getArticlesByTopic: error=', error);
  
      commit('setItemsError', true);
    } finally {
      dispatch('app/hideSpinner', null, { root: true });
    }
  },

  async searchArticles({ commit, dispatch, state }, query) {
    dispatch('app/showSpinner', null, { root: true });

    const name = 'Search';

    console.log('actions.searchArticles: name=', (state.name ? state.name : 'NONE'), '->', name);
    console.log('actions.searchArticles: query=', (state.query ? state.query : 'NONE'), '->', query);

    if ((state.name && state.name != name) || (state.query && state.query != query)) {
      commit('initializeItems');
    }

    console.log('actions.searchArticles: page=', state.page);

    try {
      const response = await api.searchArticles(query, state.page + 1);

      console.log('actions.searchArticles: response=', response);
  
      commit('incrementPage', name);
  
      commit('setQuery', query);
      commit('addArticles', response.data);
    } catch (error) {
      console.log('actions.searchArticles: error=', error);
  
      commit('setItemsError', true);
    } finally {
      dispatch('app/hideSpinner', null, { root: true });
    }
  },

  async getArticle({ commit, dispatch }, id) {
    dispatch('app/showSpinner', null, { root: true });

    console.log('actions.getArticle: id=', id);

    try {
      const response = await api.getArticle(id);

      console.log('actions.getArticles: response=', response);
  
      commit('setArticle', response.data.article);
    } catch (error) {
      console.log('actions.getArticle: error=', error);
      
      commit('setItemError', true);
    } finally {
      dispatch('app/hideSpinner', null, { root: true });
    }
  },
};

const mutations = {
  incrementPage(state, name) {
    console.log('mutations.incrementPage: name=', state.name, '->', name);
    console.log('mutations.incrementPage: page=', state.page, '->', state.page + 1);

    state.name = name;
    state.page++;
  },

  setHeadlines(state, headlines) {
    console.log('mutations.setHeadlines: headlines=', headlines);

    state.headlines = headlines;
  },

  setTopic(state, topic) {
    console.log('mutations.setTopic: topic=', topic);

    state.topic = topic;
  },

  setQuery(state, query) {
    console.log('mutations.setQuery: query=', query);

    state.query = query;
  },

  addArticles(state, { articles, hasNext }) {
    console.log('mutations.addArticles: articles=', articles);
    console.log('mutations.addArticles: hasNext=', state.hasNext, '->', hasNext);

    if (state.items) {
      state.items.push.apply(state.items, articles);
    } else {
      state.items = articles;
    }
    state.hasNext = hasNext;
  },

  initializeItems(state) {
    console.log('mutations.initializeItems');

    state.name = null;
    state.page = 0;
    state.headlines = null;
    state.topic = null;
    state.query = null;
    state.items = null;
    state.hasNext = false;
    state.itemsError = false;
  },

  setItemsError(state, error) {
    console.log('mutations.setItemsError: error=', error);

    state.itemsError = error;
  },

  setKeyword(state, keyword) {
    console.log('mutations.setKeyword: keyword=', keyword);

    state.keyword = keyword;
  },

  initializeKeywordAndItems(state) {
    console.log('mutations.initializeKeywordAndItems');

    state.keyword = null;

    state.name = null;
    state.page = 0;
    state.headlines = null;
    state.topic = null;
    state.query = null;
    state.items = null;
    state.hasNext = false;
    state.itemsError = false;
  },

  setArticle(state, article) {
    console.log('mutations.setArticle: article=', article);

    state.item = article;
  },

  initializeItem(state) {
    console.log('mutations.initializeItem');

    state.item = null;
    state.itemError = false;
  },

  setItemError(state, error) {
    console.log('mutations.setItemError: error=', error);

    state.itemError = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
