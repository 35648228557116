import api from '../../api';

const state = () => ({
  drawer: false,
  topics: null,
  loading: false,
});

const getters = {};

const actions = {
  async getTopics({ commit }) {
    try {
      const response = await api.getTopics();

      console.log('actions.getTopics: response=', response);
  
      commit('setTopics', response.data.topics);
    } catch (error) {
      console.log('actions.getTopics: error=', error);
    }
  },

  showSpinner({ commit }) {
    console.log('showSpinner');

    commit('setLoading', true);
  },

  hideSpinner({ commit }) {
    console.log('hideSpinner');

    commit('setLoading', false);
  },
};

const mutations = {
  setDrawer(state, drawer) {
    console.log('mutations.setDrawer: drawer=', drawer);

    state.drawer = drawer;
  },

  toggleDrawer(state) {
    console.log('mutations.toggleDrawer: drawer=', state.drawer, '->', !state.drawer);

    state.drawer = !state.drawer;
  },

  setTopics(state, topics) {
    console.log('mutations.setTopics: topics=', topics);

    state.topics = topics;
  },

  setLoading(state, loading) {
    console.log('mutations.setLoading: loading=', loading);

    state.loading = loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
