<template>
  <v-app-bar app class="white app-bar-light">
    <v-app-bar-nav-icon @click="toggleDrawer" />
    <v-img src="/logo.gif" max-height="40" contain />
    <v-progress-linear absolute bottom :active="loading" :indeterminate="loading" />
    <v-btn icon @click="goToSearchPage()">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AppBar',
  computed: {
    ...mapState ({
      loading: state => state.app.loading,
    }),
  },
  methods: {
    ...mapMutations('app', [
      'toggleDrawer',
    ]),
    ...mapMutations('articles', [
      'initializeKeywordAndItems',
    ]),
    goToSearchPage() {
      this.initializeKeywordAndItems();

      if (this.$route.name !== 'Search') {
        this.$router.push({ path: '/search'});
      }
    },
  },
}
</script>

<style>
.app-bar-light {
  box-shadow: 0 1px 0 0 #c4c4c4 !important;
}
</style>
